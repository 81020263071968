<template lang="">
  <Card>
    <template #header>
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        Fiili Kullanım Niteliği Bilgileri
      </div>
    </template>

    <div class="row d-flex align-items-center mx-1">
      <div class="col-8 p-0">
        <b-form-group class="add-style">
          <label style="color: #2196f3" for="ilAdi">Adı</label>
          <b-form-input type="text" id="ad" required />
        </b-form-group>
      </div>
      <div class="col-4 p-0">
      <button class="saveButton mx-auto ">Kaydet</button>

      </div>
    </div>
  </Card>
</template>



<script>
export default {};
</script>




<style >
.saveButton {
    background-color: rgb(80, 184, 80);
    padding: 8px 12px;
    border-radius: 10px;
    color: white;
    display: flex;
    justify-content: center;
  }
  .saveButton:hover {
    background-color: rgb(60, 151, 60);
    transition: 0.2s;
  }
</style>
